.tabs {
  height: 100%;
  :global(.ant-tabs-nav) {
    background-color: #fff;
  }

  :global(.ant-tabs-nav-wrap) {
    background: #fff;
    padding-left: 20px;
  }

  :global(.ant-tabs-content) {
    height: 100%;
  }

  :global(.ant-tabs-extra-content) {
    padding-right: 14px;
  }

  :global(.ant-tabs-tabpane) {
    height: 100%;
  }
}
