.section {
  margin: 0 20px 26px;
}

.filterSection {
  margin: 0 0 20px;
  padding: 24px;
  background-color: white;
  border-radius: 2px;
}

.tableSection {
  padding: 24px;
  background-color: white;
  max-width: 100%;
  //overflow: scroll;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  :global(.ant-tabs-nav) {
    background-color: #fff;
  }

  :global(.ant-tabs-nav-wrap) {
    background: #fff;
    padding-left: 20px;
  }

  :global(.ant-tabs-extra-content) {
    padding-right: 14px;
  }
}

.newSection {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 24px;
}

.flexSection {
  &:extend(.newSection);
  display: flex;
  flex-direction: column;
}

.flexFullSection {
  &:extend(.flexSection);
  flex: 1;
}

.rowSection {
  display: flex;
  flex-direction: row;
}

.transfer {
  :global(.ant-transfer-list-body-customize-wrapper) {
    flex: auto;
    overflow: scroll;
  }
}
