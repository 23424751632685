.App {
  //text-align: center;
  min-height: 100vh;
}

.nameBtn {
  width: 58px;
  height: 24px;
  background: rgba(220, 248, 247, 1);
  border-radius: 2px;
  border: 1px solid rgba(160, 239, 236, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: rgba(0, 183, 174, 1);
  line-height: 22px;
}

//.BMap_cpyCtrl {
//  visibility: hidden;
//}


//todo: 在合并主分支时一起改掉
.ant-page-header.has-breadcrumb {
  margin-bottom: 20px;
  padding-top: 19px !important;
}
