.text {
  line-height: 26px;
  font-size: 30px;
  color: #282A36;
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00B7AE;

  .content {
    width: 1200px;
    height: 900px;
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      position: absolute;
      right: 45px;
      top: 31px;
    }

    .cover {
      height: 600px;
      width: 592px;
      margin: 151px 70px 148px 78px;
    }

    .form {
      .text-top {
        &:extend(.text);
      }

      .text-bottom {
        &:extend(.text);
        font-weight: bold;
        margin-bottom: 47px;
      }

      .input {
        width: 330px;
        height: 40px;
        border: none;
        box-shadow: none;
        outline: none;
        border-radius: 4px;
        background-color: #F7F9FA;
      }

      .btn {
        width: 100%;
        height: 40px;
      }
    }
  }
}
