@padding-horizontal: 24px;
@modal-confirm-body-padding: 16px @padding-horizontal 10px;
@modal-footer-padding-horizontal: @padding-horizontal;

// modal
.ant-modal-title {
  font-weight: 600;
}

.ant-modal-footer {
  border-top: none;
}

.ant-modal-header {
  border-bottom: none;
  padding: 16px 20px;
  position: relative;
}

.ant-modal-header:after {
  content: " ";
  display: block;
  position: absolute;
  background-color: rgba(0,0,0,.06);
  height: 1px;
  bottom: 0;
  left: @padding-horizontal;
  right: @padding-horizontal;
}

// confirm modal
.ant-modal-confirm-body .ant-modal-confirm-title {
  padding-bottom: 16px;
  position: relative;
  font-weight: 600;
}

.ant-modal-confirm-body .ant-modal-confirm-title:after {
  content: " ";
  display: block;
  position: absolute;
  background-color: rgba(0,0,0,0.06);
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 24px;
  padding-bottom: 1px;
}

// 修正 Modal.confirm 的 icon 属性存在的情况下，title 分隔线显示问题
.anticon ~ .ant-modal-confirm-title {
  overflow: visible;
}
