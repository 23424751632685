.index{
    padding-left: 24px;
    padding-right: 26px;
    padding-top: 21px;
}
.form{
    margin-top: 30px;
}
.bottom{
    margin-top: 60px;
    margin-left: 140px;
}